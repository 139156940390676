var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{ref:"filtersContainer",staticClass:"mb-1"},[_c('v-col',{staticClass:"d-flex align-center pb-0",attrs:{"cols":"3"}},[_c('span',{staticClass:"text-h5 pt-3"},[_vm._v("Отчет по производству")])]),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"5"}},[_c('v-autocomplete',{attrs:{"items":_vm.products,"loading":_vm.productsLoading,"item-value":"id","item-text":"name","label":"Продукты","multiple":"","clearable":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',[_vm._v(" "+_vm._s(item.name)),(_vm.filter.producttype_id.length >0)?_c('span',{staticClass:"pr-1"},[_vm._v(", ")]):_vm._e()]):_vm._e(),(index === 1)?_c('span',[_vm._v(_vm._s(item.name))]):_vm._e(),(index === 2)?_c('span',{staticClass:"pl-1"},[_vm._v(" и еще "+_vm._s(_vm.filter.producttype_id.length - 2)+" ")]):_vm._e()]}}]),model:{value:(_vm.filter.producttype_id),callback:function ($$v) {_vm.$set(_vm.filter, "producttype_id", $$v)},expression:"filter.producttype_id"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"bottom":"","left":"","transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.fromDateFormatted,"label":"с","prepend-icon":"mdi-calendar","readonly":"","hide-details":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.fromDateMenu),callback:function ($$v) {_vm.fromDateMenu=$$v},expression:"fromDateMenu"}},[_c('v-date-picker',{on:{"input":_vm.dateChanged},model:{value:(_vm.filter.from_date),callback:function ($$v) {_vm.$set(_vm.filter, "from_date", $$v)},expression:"filter.from_date"}})],1)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"bottom":"","left":"","transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.toDateFormatted,"label":"по","prepend-icon":"mdi-calendar","readonly":"","hide-details":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.toDateMenu),callback:function ($$v) {_vm.toDateMenu=$$v},expression:"toDateMenu"}},[_c('v-date-picker',{on:{"input":_vm.dateChanged},model:{value:(_vm.filter.to_date),callback:function ($$v) {_vm.$set(_vm.filter, "to_date", $$v)},expression:"filter.to_date"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":!!_vm.fetching,"items":_vm.report,"items-per-page":_vm.filter.limit,"height":_vm.dataTableHeight,"disable-items-per-page":"","hide-default-footer":"","fixed-header":"","no-data-text":"Нет данных","loading-text":"Данные загружаются"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.filter.limit * (_vm.filter.page - 1) + index + 1))]),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(item.products_total))])])]}}])}),_c('pagination',{ref:"paginationContainer",attrs:{"limit":_vm.limit,"page":_vm.page,"total-pages":_vm.totalPages},on:{"update:limit":function($event){_vm.limit=$event},"update:page":function($event){_vm.page=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }