<template>
  <v-container fluid>
    <v-row ref="filtersContainer" class="mb-1" dense>
      <v-col cols="5" class="d-flex align-center">
        <span class="text-h5">Отчет по остатку материалов</span>
      </v-col>
      <v-col offset="4" cols="3" class="text-right">
        <span class="font-weight-bold success--text text--darken-3">Остаток: {{totals.remain|money}}</span>
      </v-col>
      <v-col cols="9">
        <v-autocomplete v-model="filter.material_ids"
                        :items="materials"
                        :loading="materialsLoading"
                        item-value="id" item-text="name"
                        label="Материалы" multiple clearable hide-details
        >
          <template v-slot:selection="{item, index}">
            <span v-if="index === 0">
              {{item.name}}<span class="pr-1" v-if="filter.material_ids.length >0">, </span>
            </span>
            <span v-if="index === 1">{{item.name}}</span>
            <span class="pl-1" v-if="index === 2">
              и еще {{filter.material_ids.length - 2}}
            </span>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="3">
        <v-menu v-model="toDateMenu" :close-on-content-click="false"
                bottom left offset-y
                transition="scale-transition" max-width="290px" min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="toDateFormatted"
              label="К дате" prepend-icon="mdi-calendar"
              readonly v-bind="attrs" v-on="on" hide-details
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.to_date" @input="dateChanged"></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-data-table :items="report"
      :headers="nonSortableHeaders" :loading="!!fetching" :items-per-page="1000"
      :height="dataTableHeight" disable-items-per-page hide-default-footer fixed-header
      no-data-text="Нет данных" loading-text="Данные загружаются"
    >
      <template #item="{item, index}">
        <tr>
          <td class="text-center">{{filter.limit * (filter.page - 1) + index + 1}}</td>
          <td>{{item.name}}</td>
          <td class="text-center">{{(item.measurement || {}).name}}</td>
          <td class="text-right">{{item.priyomka_elements_total|prettyInt}}</td>
          <td class="text-right">{{item.rasxod_elements_total|prettyInt}}</td>
          <td class="text-right">{{item.buy_price_last|prettyInt(null, 0, false)}}</td>
          <td class="text-right">{{item.buy_price_usd_last|money('usd', 0, false)}}</td>
          <td class="text-right">{{item._last_priyomka === 'usd' ? 'в долл' : 'в сумах'}}</td>
          <td class="text-right"
              :class="item.remain <= item.min_notified_amount ? 'red--text lighten-3 font-weight-bold' : ''"
          >{{item.remain|prettyInt}}</td>
          <td class="text-right">{{item.buy_price_by_currency|money(null, 0, false)}}</td>
          <td class="text-right">{{item.remain_price|money(null, 0, false)}}</td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {createNamespacedHelpers, mapGetters, mapState as mapStateDefault} from 'vuex'
const {mapState, mapActions} = createNamespacedHelpers('Report')

import moment from 'moment'
import {SERVER_DATE_FORMAT, USER_DATE_FORMAT} from '@/filters'
import {hasFilter} from '@/mixins'

export default {
  name: 'report-material',

  mixins: [hasFilter],

  customFilters: {
    limit: 1000,
    report: 'materialReport',
    material_ids: [],
    to_date: moment().format(SERVER_DATE_FORMAT),
  },

  data() {
    return {
      toDateMenu: false,
    }
  },

  created() {
    this.fetch(this.filter)
  },

  computed: {
    toDateFormatted() {
      return moment(this.filter.to_date).format(USER_DATE_FORMAT)
    },

    ...mapState([
      'fetching',
      'data',
    ]),

    headers() {
      return [
        {text: '#', align: 'center', width: '70'},
        {text: 'Название'},
        {text: '', align: 'center'},
        {text: 'Приход', align: 'right', width: '9em'},
        {text: 'Расход', align: 'right', width: '9em'},
        {text: 'Цена, сум', align: 'right', width: '9em'},
        {text: 'Цена, $', align: 'right'},
        {text: 'Посл приёмка', align: 'right'},
        {text: 'Остаток', align: 'right'},
        {text: 'Цена остатка, сум', align: 'right'},
        {text: 'Сумма остатка, сум', align: 'right'},
      ]
    },

    ...mapStateDefault('Material', {
      materials: state => (state.data?.results || []),
      materialsLoading: state => !!state.fetching,
    }),

    report() {
      return this.data?.results || []
    },

    totals() {
      return this.data?.total || 0
    }
  },

  methods: {
    ...mapActions(['fetch']),

    dateChanged() {
      this.toDateMenu = false
      this.$nextTick(() => this.fetch(this.filter))
    }
  },

  mounted() {
    this.$eventBus.$emit('setTitle', 'Отчет по остатку материалов')
    this.$store.dispatch('Material/fetch')
  },
}
</script>
