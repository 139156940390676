<template>
  <v-container fluid>
    <v-row>
      <v-col cols="9" class="d-flex align-center">
        <span class="text-h5 pt-3">Оборот по месяцам</span>
      </v-col>
      <v-col cols="3">
        <v-select v-model="filter.year"
                  :items="years"
                  item-value="id" item-text="value"
                  label="за год" prepend-icon="mdi-calendar" hide-details
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <div v-if="!!fetching" class="ma-4 text-center">
          <v-progress-linear color="primary" indeterminate/>
        </div>
        <v-simple-table v-else class="report-by-month">
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-center">Месяц</th>
              <th class="text-right">Дебет</th>
              <th class="text-right">Кредит</th>
              <th class="text-right">Разница</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="i in 12" :key="i" @click="showDetails(i)" class="report-row">
              <td class="text-center" @click="showDetails(i)">
                {{getHeaderDate(i)}}
              </td>
              <td class="text-right" @click="showDetails(i, 'debet')">
                {{getCellData('debet', i).amount_sum|money}}
              </td>
              <td class="text-right" @click="showDetails(i, 'credit')">
                {{getCellData('credit', i).amount_sum|money}}
              </td>
              <td  @click="showDetails(i)" class="text-right" :class="{
                'red lighten-4': getCellData('debet', i).amount_sum < getCellData('credit', i).amount_sum,
                'success lighten-5': getCellData('debet', i).amount_sum > getCellData('credit', i).amount_sum,
              }">
                {{getCellData('debet', i).amount_sum - getCellData('credit', i).amount_sum|money}}
              </td>
            </tr>
            <tr class="font-weight-bold">
              <td class="text-center">Итог</td>
              <td class="text-right">
                {{getTotalsCellData.debet|money}}
              </td>
              <td class="text-right">
                {{getTotalsCellData.credit|money}}
              </td>
              <td class="text-right" :class="{
                'red lighten-4': getTotalsCellData.diff < 0,
                'success lighten-5': getTotalsCellData.diff > 0,
              }">
                {{getTotalsCellData.diff|money}}
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {createNamespacedHelpers, mapState as mapStateDefault} from 'vuex'
  import moment from 'moment'
  import {hasFilter} from '@/mixins'
  import {SERVER_DATE_FORMAT} from '@/filters'

  const {mapState, mapActions} = createNamespacedHelpers('Report')

  export default {
    name: 'report-turnonver-by-months',

    mixins: [hasFilter],

    customFilters: {
      year: (new Date).getFullYear(),
      report: 'turnoverByMonths',
    },

    data() {
      return {
        years: [2022, 2023, 2024, 2025, 2026].filter(d => 1 || d <= (new Date).getFullYear()).map(v => {
          return {id: v, value: v}
        }),
      }
    },

    created() {
      this.fetch(this.filter)
    },

    computed: {
      ...mapState([
        'fetching',
        'data',
      ]),

      report() {
        return this.data || []
      },

      getTotalsCellData(type) {
        return (this.report || []).reduce((acc, el) => {
          if (el.type === 'debet')
            acc.debet += el.amount_sum
          if (el.type === 'credit')
            acc.credit += el.amount_sum

          acc.diff = acc.debet - acc.credit

          return acc
        }, {debet: 0, credit: 0, diff: 0})
      },
    },

    methods: {
      ...mapActions(['fetch']),

      showDetails(month, type) {
        this.$router.push({
          name: 'warehouse.report.expenditures-by-source',
          query: {
            reg_date_before: moment(this.getCellDate(month)).endOf('month').format(SERVER_DATE_FORMAT),
            reg_date_after: this.getCellDate(month),
            ...(type ? {type} : {}),
          }
        })
      },

      getHeaderDate(month) {
        return moment(this.getCellDate(month)).locale('ru').format('MMM')
      },

      getCellDate(month) {
        return `${this.filter.year}-${month.toString().padStart(2, '0')}-01`
      },

      getCellData(type, month) {
        return this.report.find(rI => {
          return rI.type === type && rI.month === this.getCellDate(month)
        }) || {amount_sum: 0}
      },
    },

    mounted() {
      this.$eventBus.$emit('setTitle', 'Оборот по месяцам')
    },
  }
</script>

<style scoped>
  .report-row {
    cursor: pointer;
  }
  .report-by-month tr>th:first-child,
  .report-by-month tr>td:first-child {
    max-width: 70px;
  }
  .report-by-month tr>th:not(:first-child),
  .report-by-month tr>td:not(:first-child) {
    border-left: 1px solid #eee;
  }
</style>
