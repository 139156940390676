<template>
  <v-container fluid>
    <v-row ref="filtersContainer">
      <v-col cols="12" class="d-flex align-center">
        <span class="text-h5">Отчет по доходам и расходам</span>
      </v-col>
      <v-col cols="2">
        <v-menu v-model="fromDateMenu" :close-on-content-click="false"
                transition="scale-transition" offset-y max-width="290px" min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="fromDateFormatted"
                          label="с" prepend-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.reg_date_after" @input="dateChanged"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2">
        <v-menu v-model="toDateMenu" :close-on-content-click="false"
                transition="scale-transition" offset-y max-width="290px" min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="toDateFormatted"
                          label="по" prepend-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.reg_date_before" @input="dateChanged"></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-data-table :items="report"
      :headers="nonSortableHeaders"
      :loading="!!fetching" :items-per-page="filter.limit"
      :height="dataTableHeight" disable-items-per-page hide-default-footer
      items-per-page="1000"
      no-data-text="Нет данных" loading-text="Данные загружаются"
    >
      <template #item="{item, index}">
        <tr>
          <td @click="showOperations(item.source)" class="link-like">{{getType(item.source).label}}</td>
          <td @click="showOperations(item.source, 'debet')" class="text-right link-like">{{getEl(item.source, 'debet') | money}}</td>
          <td @click="showOperations(item.source, 'credit')" class="text-right link-like">{{getEl(item.source, 'credit') | money}}</td>
          <td @click="showOperations(item.source)" class="text-right link-like" :class="getDiff(item.source) > 0 ? 'success--text' : 'error--text'">
            {{getDiff(item.source) | money}}
          </td>
        </tr>
        <tr class="font-weight-black" v-if="index + 1 == report.length">
          <td>Итог:</td>
          <td class="text-right">
            {{total('debet') | money}}
          </td>
          <td class="text-right">
            {{total('credit') | money}}
          </td>
          <td class="text-right" :class="total() > 0 ? 'success--text' : 'error--text'">
            {{total() | money}}
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  import {createNamespacedHelpers, mapGetters as defaultMapGetters} from 'vuex'
  import moment from 'moment'
  import {SERVER_DATE_FORMAT, USER_DATE_FORMAT} from '@/filters'
  import {hasFilter, hasDialog} from '@/mixins'

  const {mapState, mapActions, mapGetters} = createNamespacedHelpers('Report')

  export default {
    name: 'report-expenditures-by-source',

    mixins: [hasFilter, hasDialog],

    customFilters: {
      report: 'expendituresBySource',
      reg_date_before: moment().format(SERVER_DATE_FORMAT),
      reg_date_after: moment().subtract(1, 'month').format(SERVER_DATE_FORMAT),
    },

    storedFilters: {
      'reg_date_before': String,
      'reg_date_after': String,
    },

    created() {
      this.fetch(this.filter)
    },

    computed: {
      ...mapState(['fetching', 'data']),
      ...defaultMapGetters('UniversalExpense', ['getType']),

      headers() {
        return [
          {text: 'Тип'},
          {text: 'Дебет', align: 'right'},
          {text: 'Кредит', align: 'right'},
          {text: 'Прибыль / Убыток', align: 'right'},
        ]
      },

      report() {
        return this.data || []
      },

      toDateFormatted() {
        return moment(this.filter.reg_date_before).format(USER_DATE_FORMAT)
      },

      fromDateFormatted() {
        return moment(this.filter.reg_date_after).format(USER_DATE_FORMAT)
      },
    },

    methods: {
      ...mapActions(['fetch']),

      getEl(source, type) {
        return this.report.find(e => e.source === source && e.type === type)?.amount_sum || 0
      },

      getDiff(source) {
        return this.getEl(source, 'debet') - this.getEl(source, 'credit')
      },

      dateChanged() {
        this.toDateMenu = false
        this.fromDateMenu = false
        this.$nextTick(() => this.fetch(this.filter))
      },

      showOperations(source, type = null) {
        this.$router.push({
          name: 'warehouse.report.operations',
          query: {
            source: source,
            type: type,
            reg_date_after: this.filter.reg_date_after,
            reg_date_before: this.filter.reg_date_before,
          }
        })
      },

      total(type) {
        return this.report.reduce((acc, el) => {
          if (type && el.type !== type) {
            return acc
          }

          return acc + el.amount_sum * (el.type === 'credit' ? -1 : 1)
        }, 0)
      }
    },

    mounted() {
      this.$eventBus.$emit('setTitle', 'Отчет по доходам и расходам')
    },
  }
</script>
