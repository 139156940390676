<template>
  <v-container fluid>
    <v-row ref="filtersContainer" class="mb-1">
      <v-col cols="8" class="d-flex align-center pb-0">
        <span class="text-h5 pt-3">Промежуточный отчет продаж</span>
      </v-col>
      <v-col cols="2" class="pb-0">
        <v-menu v-model="fromDateMenu" :close-on-content-click="false" bottom left
                transition="scale-transition" offset-y max-width="290px" min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="fromDateFormatted"
                          label="с" prepend-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on" hide-details
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.from_date" @input="dateChanged"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2" class="pb-0">
        <v-menu v-model="toDateMenu" :close-on-content-click="false" bottom left
                transition="scale-transition" offset-y max-width="290px" min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="toDateFormatted"
                          label="по" prepend-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on" hide-details
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.to_date" @input="dateChanged"></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="9">
        <v-data-table :headers="headers" :loading="!!fetching"
                      :items="report" :items-per-page="filter.limit"
                      :height="dataTableHeight" disable-items-per-page hide-default-footer fixed-header
                      no-data-text="Нет данных" loading-text="Данные загружаются"
        >
          <template #item="{item, index}">
            <tr>
              <td class="text-center">{{filter.limit * (filter.page - 1) + index + 1}}</td>
              <td>{{item.product_name}}</td>
              <td class="text-right">{{item.amount|prettyInt}}</td>
              <td class="text-right">{{item['uzs']|money('uzs')}}</td>
              <td class="text-right">{{item['usd']|money('usd')}}</td>
            </tr>
          </template>
          <template #body.append>
            <tr class="font-weight-bold bg-total">
              <td colspan="2" class="text-left pl-12">Итого:</td>
              <td class="text-right">{{getTotals('amount')|prettyInt}}</td>
              <td class="text-right">{{getTotals('uzs')|money(null, true)}}</td>
              <td class="text-right">{{getTotals('usd')|money('usd', true)}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {createNamespacedHelpers, mapGetters} from 'vuex'
  const {mapState, mapActions} = createNamespacedHelpers('Report')

  import moment from 'moment'
  import {SERVER_DATE_FORMAT, USER_DATE_FORMAT} from '@/filters'

  import {hasFilter} from '@/mixins'

  export default {
    name: 'report-intermediate-consignment',

    mixins: [hasFilter],

    customFilters: {
      limit: 1000,
      to_date: moment().format(SERVER_DATE_FORMAT),
      from_date: moment().format(SERVER_DATE_FORMAT),
      report: 'intermediateConsignment',
    },

    data() {
      return {
        toDateMenu: false,
        fromDateMenu: false,
      }
    },

    created() {
      this.fetch(this.filter)
    },

    computed: {
      ...mapState([
        'fetching',
        'data',
      ]),

      ...mapGetters('CurrencyRate', ['getCurrency']),

      headers() {
        return [
          {text: '#', align: 'center', width: '100'},
          {text: 'Название'},
          {text: 'Количество', align: 'right'},
          {text: 'В сумах', align: 'right'},
          {text: 'В долларах', align: 'right'},
        ]
      },

      report() {
        return Object.values(
          (this.data || []).reduce((items, item) => {
            if (!items[item.product_type]) {
              items[item.product_type] = item
            }
            items[item.product_type][item.currency] = item.total

            return items
          }, {})
        )
      },

      toDateFormatted() {
        return moment(this.filter.to_date).format(USER_DATE_FORMAT)
      },

      fromDateFormatted() {
        return this.filter.from_date && moment(this.filter.from_date).format(USER_DATE_FORMAT)
      },
    },

    methods: {
      ...mapActions(['fetch']),

      dateChanged() {
        this.toDateMenu = false
        this.fromDateMenu = false
      },

      getTotals(field) {
        return this.report.reduce((total, el) => {
          total += el[field] || 0
          return total
        }, 0)
      }
    },

    mounted() {
      this.$eventBus.$emit('setTitle', 'Промежуточный отчет продаж')
    },
  }
</script>
