<template>
  <v-card>
    <template v-if="fetchingItem">
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </template>
    <template v-else>
    <v-card-title>
      {{(getPaymentType(item.type)|| {}).altName}} #{{ item.id }} от {{ item.reg_date|stringDateTime }}
      <v-chip class="ml-2" color="indigo" text-color="white" small>{{sourceName}}</v-chip>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('dialog:close')">
        <v-icon color="error">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="text-body-1">
      <div v-if="item.source">Тип операции: {{getSourceType(item.source).label}}</div>

      <template v-if="item.source === 'product'">
        <div>Документ продукта: #{{item.source_object_info.product_doc.id}}</div>
        <div>Продукт: {{item.source_object_info.product_type.name}}</div>
        <div>Кол-во: {{item.source_object_info.amount}}</div>
      </template>

      <template v-if="item.source === 'counteragent'">
        <div>Контрагент: {{counteragent.full_name}}</div>
        <div>Комментарий: {{item.source_object_info.description}}</div>
      </template>

      <template v-else-if="item.source === 'transport_expenses'">
        <div>Модель ТС: {{transport.model}}</div>
        <div>Номер ТС: {{transport.number}}</div>
        <div v-if="!isPaymentSource">Тип: {{getTransportExpenseType(item.source_object_info.type).label}}</div>
        <div>Доп инфо: {{item.source_object_info.description}}</div>
      </template>

      <template v-else-if="item.source === 'payment'">
        <div>Тип платежа: {{(item)}}</div>
      </template>
      <div v-if="(item.source_object_info || {}).staff">Сотрудник: #{{item.source_object_info.staff|full_name}}</div>
      <div v-if="(item.source_object_info || {}).reg_number">Рег. номер: #{{item.source_object_info.reg_number}}</div>
      <div v-if="(item.source_object_info || {}).cash_type">Форма платежа: {{getPaymentForm(item.source_object_info.cash_type).name}}</div>
      <div v-if="(item.source_object_info || {}).comments">Комментарий: {{item.source_object_info.comments}}</div>
      <div class="mt-3 text-h5">
        Сумма: {{item.amount|money}}
      </div>
    </v-card-text>
    </template>
  </v-card>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import ProductDetails from '@/modules/product-collection/details'
import PaymentDetails from "@/modules/payment/details.vue";

export default {
  name: 'operation-detail',

  props: {
    itemId: {
      required: true,
      default() {
        return {}
      }
    }
  },

  data() {
    return {
      fetchingItem: false,
      item: {},
    }
  },

  computed: {
    ...mapGetters('Payment', ['getPaymentType', 'getPaymentForm']),
    ...mapGetters('UniversalExpense', {getSourceType: 'getType'}),
    ...mapGetters('TransportExpense', {getTransportExpenseType: 'getType'}),
    ...mapState('Transport', {
      transport: (state) => state.model
    }),
    ...mapState('Counteragent', {
      counteragent: (state) => state.model
    }),

    isPaymentSource() {
      return this.item.source_class === 'payment'
    },
    sourceName() {
      switch (this.item.source_class) {
        case 'payment':
          return 'Платежи'
        case 'universalexpenditure':
          return 'Универсальные расходы'
        case 'staffsalarydate':
          return 'Зарплаты'
        default:
          return this.item.source_class
      }
    }
  },

  methods: {
    loadModel(item = {}) {
      if (item.source === 'transport_expenses') {
        this.$store.dispatch('Transport/show', this.item.source_object_info.transport)
      }
      if (item.source === 'counteragent') {
        this.$store.dispatch('Counteragent/show', this.item.source_object_info.counteragent)
      }
    },
  },

  created() {
    this.fetchingItem = true
    this.$store.dispatch('Report/details', this.itemId).then((response) => {
      this.item = response.body
      this.loadModel(this.item)
      this.fetchingItem = false
    })
  },

  beforeDestroy() {
  },

  components: {
    PaymentDetails,
    ProductDetails,
  }
}
</script>
