<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4">
        <v-card color="purple lighten-3" class="pa-3 white--text subtitle-1"
                elevation="3" rounded
        >
          <h2 class="mb-3">За год</h2>
          <div>Доходы: {{totals.year_totals.year_debet_total || 0 |money}}</div>
          <div>Расходы: {{totals.year_totals.year_credet_total || 0 |money}}</div>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card color="blue lighten-1" class="pa-3 white--text subtitle-1"
                elevation="3" rounded
        >
          <h2 class="mb-3">За месяц</h2>
          <div>Доходы: {{totals.month_totals.month_debet_total || 0 |money}}</div>
          <div>Расходы: {{totals.month_totals.month_credet_total || 0 |money}}</div>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card color="teal" class="pa-3 white--text subtitle-1"
                elevation="3" rounded
        >
          <h2 class="mb-3">За сегодня</h2>
          <div>Доходы: {{totals.today_totals.today_debet_total || 0 |money}}</div>
          <div>Расходы: {{totals.today_totals.today_credet_total || 0 |money}}</div>
        </v-card>
      </v-col>
      <v-col cols="4">
        <span class="text-h5">Финансовые показатели</span>
      </v-col>
      <v-col cols="12">
        <div v-if="!!fetchingDashboard.turnoverByMonths" class="text-center pa-5">
          <v-progress-circular indeterminate class="pa-4 text-center"/>
        </div>
        <div v-else>
          <highcharts ref="yearChart" :options="yearlyReport" class="pa-4 text-center"/>
        </div>
      </v-col>
      <v-col cols="12">
        <div v-if="!!fetchingDashboard.turnoverByDays" class="text-center pa-5">
          <v-progress-circular indeterminate class="pa-4 text-center"/>
        </div>
        <div v-else>
          <highcharts ref="dailyChart" :options="dailyReport"/>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {Chart} from 'highcharts-vue'
import {createNamespacedHelpers} from 'vuex'
import moment from 'moment'
import {SERVER_DATE_FORMAT, USER_DATE_FORMAT} from '@/filters'

const {mapState, mapActions} = createNamespacedHelpers('Report')

export default {
  name: 'report-dashboard',

  data() {
    return {
      turnoverByDaysFilter: {
        report: 'turnoverByDays',
        reg_date_after: moment().subtract(1, 'month').format(SERVER_DATE_FORMAT),
        reg_date_before: moment().format(SERVER_DATE_FORMAT),
      },

      turnoverByMonthsFilter: {
        report: 'turnoverByMonths',
        year: (new Date).getFullYear(),
      },

      turnoverTotalsFilter: {
        report: 'turnoverTotals',
      },

      defaultOptions: {
        chart: {
          type: 'line'
        },
        credits: false,
        noData: 'Нет данных',
        yAxis: {
          title: {
            text: 'Сумма, сум',
          },
        },
      },
    }
  },

  computed: {
    ...mapState(['fetchingDashboard', 'dashboardData']),

    yearData() {
      let data = this.dashboardData[this.turnoverByMonthsFilter.report] || []

      let result = {
        credit: [],
        debet: [],
        diff: [],
        labels: [],
      }

      for (let i = 1; i < 13; i++) {
        let month = `${this.turnoverByMonthsFilter.year}-${i.toString().padStart(2, '0')}-01`

        result.labels.push(`01-${i.toString().padStart(2, '0')}-${this.turnoverByMonthsFilter.year}`)
        result.credit.push((data.find(d => d.month === month && d.type === 'credit') || {amount_sum: 0}).amount_sum)
        result.debet.push((data.find(d => d.month === month && d.type === 'debet') || {amount_sum: 0}).amount_sum)
        result.diff.push(result.debet[i - 1] - result.credit[i - 1])
      }

      return result
    },

    yearlyReport() {
      return {
        ...this.defaultOptions,
        title: {
          text: 'По месяцам',
        },
        xAxis: [{
          categories: this.yearData.labels,
        }],
        series: [
          {
            name: 'Доход',
            data: this.yearData.debet,
          },
          {
            name: 'Расход',
            data: this.yearData.credit,
          },
          {
            name: 'Прибыль',
            data: this.yearData.diff,
            negativeColor: 'rgba(205, 0, 0)',
          },
        ]
      }
    },

    dailyData() {
      let data = this.dashboardData[this.turnoverByDaysFilter.report] || []

      let result = {
        credit: [],
        debet: [],
        diff: [],
        labels: [],
      }

      for (let d = moment(this.turnoverByDaysFilter.reg_date_after);
           d.isBefore(this.turnoverByDaysFilter.reg_date_before);
           d.add(1, 'days')
      ) {
        let date = d.format(SERVER_DATE_FORMAT)

        result.labels.push(d.format(USER_DATE_FORMAT))
        let i = result.credit.push((data.find(d => d.day === date && d.type === 'credit') || {amount_sum: 0}).amount_sum)
        let j = result.debet.push((data.find(d => d.day === date && d.type === 'debet') || {amount_sum: 0}).amount_sum)
        result.diff.push(result.debet[i - 1] - result.credit[j - 1])
      }

      return result
    },

    dailyReport() {
      return {
        ...this.defaultOptions,
        title: {
          text: 'По дням',
        },
        xAxis: [{
          categories: this.dailyData.labels,
        }],
        series: [
          {
            name: 'Доход',
            data: this.dailyData.debet,
          },
          {
            name: 'Расход',
            data: this.dailyData.credit,
          },
          {
            name: 'Прибыль',
            data: this.dailyData.diff,
            negativeColor: 'rgba(205, 0, 0)',
          },
        ]
      }
    },

    totals() {
      return this.dashboardData[this.turnoverTotalsFilter.report] || {
        year_totals: {},
        month_totals: {},
        today_totals: {},
      }
    }
  },


  created() {
    this.fetchDashboard(this.turnoverByDaysFilter)
    this.fetchDashboard(this.turnoverByMonthsFilter)
    this.fetchDashboard(this.turnoverTotalsFilter)

    this.$eventBus.$emit('setTitle', 'Финансовые показатели')
  },

  methods: {
    ...mapActions(['fetchDashboard']),
  },

  components: {
    highcharts: Chart,
  }
}
</script>
