<template>
  <v-container fluid>
    <v-row ref="filtersContainer" class="mb-1">
      <v-col cols="3" class="d-flex align-center pb-0">
        <span class="text-h5 pt-3">Отчет по производству</span>
      </v-col>
      <v-col cols="5" class="pb-0">
        <v-autocomplete v-model="filter.producttype_id"
                        :items="products"
                        :loading="productsLoading"
                        item-value="id" item-text="name"
                        label="Продукты" multiple clearable hide-details
        >
          <template v-slot:selection="{item, index}">
            <span v-if="index === 0">
              {{item.name}}<span class="pr-1" v-if="filter.producttype_id.length >0">, </span>
            </span>
            <span v-if="index === 1">{{item.name}}</span>
            <span class="pl-1" v-if="index === 2">
              и еще {{filter.producttype_id.length - 2}}
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="2" class="pb-0">
        <v-menu v-model="fromDateMenu" :close-on-content-click="false" bottom left
                transition="scale-transition" offset-y max-width="290px" min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="fromDateFormatted"
                          label="с" prepend-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on" hide-details
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.from_date" @input="dateChanged"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2" class="pb-0">
        <v-menu v-model="toDateMenu" :close-on-content-click="false" bottom left
                transition="scale-transition" offset-y max-width="290px" min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="toDateFormatted"
                          label="по" prepend-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on" hide-details
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.to_date" @input="dateChanged"></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-data-table :headers="headers" :loading="!!fetching"
      :items="report" :items-per-page="filter.limit"
      :height="dataTableHeight" disable-items-per-page hide-default-footer fixed-header
      no-data-text="Нет данных" loading-text="Данные загружаются"
    >
      <template #item="{item, index}">
        <tr>
          <td class="text-center">{{filter.limit * (filter.page - 1) + index + 1}}</td>
          <td>{{item.name}}</td>
          <td class="text-right">{{item.products_total}}</td>
        </tr>
      </template>
    </v-data-table>

    <pagination ref="paginationContainer" :limit.sync="limit" :page.sync="page" :total-pages="totalPages"></pagination>
  </v-container>
</template>

<script>
  import {createNamespacedHelpers, mapState as mapStateDefault} from 'vuex'
  const {mapState, mapActions} = createNamespacedHelpers('Report')

  import moment from 'moment'
  import {SERVER_DATE_FORMAT, USER_DATE_FORMAT} from '@/filters'

  import {hasFilter} from '@/mixins'


  export default {
    name: 'report-collection',

    mixins: [hasFilter],

    customFilters: {
      producttype_id: [],
      to_date: moment().format(SERVER_DATE_FORMAT),
      from_date: moment().format(SERVER_DATE_FORMAT),
      report: 'productCollectionReport',
    },

    data() {
      return {
        toDateMenu: false,
        fromDateMenu: false,
      }
    },

    created() {
      this.fetch(this.filter)
    },

    computed: {
      ...mapState([
        'fetching',
        'data',
      ]),

      ...mapStateDefault('Product', {
        products: state => (state.data?.results || []),
        productsLoading: state => !!state.fetching,
      }),

      headers() {
        return [
          {text: '#', align: 'center', width: '100'},
          {text: 'Название', value: 'name',},
          {text: 'Количество', value: 'products_total', align: 'right'},
        ]
      },

      report() {
        return this.data?.results || []
      },

      toDateFormatted() {
        return moment(this.filter.to_date).format(USER_DATE_FORMAT)
      },

      fromDateFormatted() {
        return this.filter.from_date && moment(this.filter.from_date).format(USER_DATE_FORMAT)
      },
    },

    methods: {
      ...mapActions(['fetch']),

      dateChanged() {
        this.toDateMenu = false
        this.fromDateMenu = false
      }
    },

    mounted() {
      this.$store.dispatch('Product/fetch')
      this.$eventBus.$emit('setTitle', 'Отчет по производству')
    },
  }
</script>
