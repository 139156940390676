var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{ref:"filtersContainer"},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-h5"},[_vm._v("Отчет по доходам и расходам")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.fromDateFormatted,"label":"с","prepend-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.fromDateMenu),callback:function ($$v) {_vm.fromDateMenu=$$v},expression:"fromDateMenu"}},[_c('v-date-picker',{on:{"input":_vm.dateChanged},model:{value:(_vm.filter.reg_date_after),callback:function ($$v) {_vm.$set(_vm.filter, "reg_date_after", $$v)},expression:"filter.reg_date_after"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.toDateFormatted,"label":"по","prepend-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.toDateMenu),callback:function ($$v) {_vm.toDateMenu=$$v},expression:"toDateMenu"}},[_c('v-date-picker',{on:{"input":_vm.dateChanged},model:{value:(_vm.filter.reg_date_before),callback:function ($$v) {_vm.$set(_vm.filter, "reg_date_before", $$v)},expression:"filter.reg_date_before"}})],1)],1)],1),_c('v-data-table',{attrs:{"items":_vm.report,"headers":_vm.nonSortableHeaders,"loading":!!_vm.fetching,"items-per-page":_vm.filter.limit,"height":_vm.dataTableHeight,"disable-items-per-page":"","hide-default-footer":"","items-per-page":"1000","no-data-text":"Нет данных","loading-text":"Данные загружаются"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',[_c('td',{staticClass:"link-like",on:{"click":function($event){return _vm.showOperations(item.source)}}},[_vm._v(_vm._s(_vm.getType(item.source).label))]),_c('td',{staticClass:"text-right link-like",on:{"click":function($event){return _vm.showOperations(item.source, 'debet')}}},[_vm._v(_vm._s(_vm._f("money")(_vm.getEl(item.source, 'debet'))))]),_c('td',{staticClass:"text-right link-like",on:{"click":function($event){return _vm.showOperations(item.source, 'credit')}}},[_vm._v(_vm._s(_vm._f("money")(_vm.getEl(item.source, 'credit'))))]),_c('td',{staticClass:"text-right link-like",class:_vm.getDiff(item.source) > 0 ? 'success--text' : 'error--text',on:{"click":function($event){return _vm.showOperations(item.source)}}},[_vm._v(" "+_vm._s(_vm._f("money")(_vm.getDiff(item.source)))+" ")])]),(index + 1 == _vm.report.length)?_c('tr',{staticClass:"font-weight-black"},[_c('td',[_vm._v("Итог:")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("money")(_vm.total('debet')))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("money")(_vm.total('credit')))+" ")]),_c('td',{staticClass:"text-right",class:_vm.total() > 0 ? 'success--text' : 'error--text'},[_vm._v(" "+_vm._s(_vm._f("money")(_vm.total()))+" ")])]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }