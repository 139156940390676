var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{ref:"filtersContainer",staticClass:"mb-1",attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"5"}},[_c('span',{staticClass:"text-h5"},[_vm._v("Отчет по остатку материалов")])]),_c('v-col',{staticClass:"text-right",attrs:{"offset":"4","cols":"3"}},[_c('span',{staticClass:"font-weight-bold success--text text--darken-3"},[_vm._v("Остаток: "+_vm._s(_vm._f("money")(_vm.totals.remain)))])]),_c('v-col',{attrs:{"cols":"9"}},[_c('v-autocomplete',{attrs:{"items":_vm.materials,"loading":_vm.materialsLoading,"item-value":"id","item-text":"name","label":"Материалы","multiple":"","clearable":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',[_vm._v(" "+_vm._s(item.name)),(_vm.filter.material_ids.length >0)?_c('span',{staticClass:"pr-1"},[_vm._v(", ")]):_vm._e()]):_vm._e(),(index === 1)?_c('span',[_vm._v(_vm._s(item.name))]):_vm._e(),(index === 2)?_c('span',{staticClass:"pl-1"},[_vm._v(" и еще "+_vm._s(_vm.filter.material_ids.length - 2)+" ")]):_vm._e()]}}]),model:{value:(_vm.filter.material_ids),callback:function ($$v) {_vm.$set(_vm.filter, "material_ids", $$v)},expression:"filter.material_ids"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"bottom":"","left":"","offset-y":"","transition":"scale-transition","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"К дате","prepend-icon":"mdi-calendar","readonly":"","hide-details":""},model:{value:(_vm.toDateFormatted),callback:function ($$v) {_vm.toDateFormatted=$$v},expression:"toDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.toDateMenu),callback:function ($$v) {_vm.toDateMenu=$$v},expression:"toDateMenu"}},[_c('v-date-picker',{on:{"input":_vm.dateChanged},model:{value:(_vm.filter.to_date),callback:function ($$v) {_vm.$set(_vm.filter, "to_date", $$v)},expression:"filter.to_date"}})],1)],1)],1),_c('v-data-table',{attrs:{"items":_vm.report,"headers":_vm.nonSortableHeaders,"loading":!!_vm.fetching,"items-per-page":1000,"height":_vm.dataTableHeight,"disable-items-per-page":"","hide-default-footer":"","fixed-header":"","no-data-text":"Нет данных","loading-text":"Данные загружаются"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.filter.limit * (_vm.filter.page - 1) + index + 1))]),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s((item.measurement || {}).name))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("prettyInt")(item.priyomka_elements_total)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("prettyInt")(item.rasxod_elements_total)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("prettyInt")(item.buy_price_last,null, 0, false)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("money")(item.buy_price_usd_last,'usd', 0, false)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(item._last_priyomka === 'usd' ? 'в долл' : 'в сумах'))]),_c('td',{staticClass:"text-right",class:item.remain <= item.min_notified_amount ? 'red--text lighten-3 font-weight-bold' : ''},[_vm._v(_vm._s(_vm._f("prettyInt")(item.remain)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("money")(item.buy_price_by_currency,null, 0, false)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("money")(item.remain_price,null, 0, false)))])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }