<template>
  <v-container fluid>
    <v-row ref="filtersContainer" class="mb-1">
      <v-col cols="4" class="d-flex align-center pb-0">
        <span class="text-h5">Отчет по остатку продуктов</span>
      </v-col>
      <v-col cols="8" class="pb-0">
        <v-autocomplete v-model="filter.producttype_id"
                        :items="products"
                        :loading="productsLoading"
                        item-value="id" item-text="name"
                        label="Продукты" multiple clearable hide-details
        >
          <template v-slot:selection="{item, index}">
            <span v-if="index === 0">
              {{item.name}}<span class="pr-1" v-if="filter.producttype_id.length >0"> </span>
            </span>
            <span class="pl-1" v-if="index === 1">
              и еще {{filter.producttype_id.length - 1}}
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="1" class="font-weight-bold indigo--text text--darken-2">Всего произведено:</v-col>
      <v-col cols="3" class="text-right font-weight-bold indigo--text text--darken-2">
        <div>{{ (totals.produce_total_uzs || 0)|money }}</div>
        <div>{{ (totals.produce_total_usd || 0)|money('usd') }}</div>
      </v-col>
      <v-col cols="1" class="font-weight-bold purple--text text--darken-3">Всего продаж:</v-col>
      <v-col cols="3" class="text-right font-weight-bold purple--text text--darken-3">
        <div>{{ (totals.consignment_total_uzs || 0)|money }}</div>
        <div>{{ (totals.consignment_total_usd || 0)|money('usd') }}</div>
      </v-col>
      <v-col cols="1" class="font-weight-bold">
        <span class="ml-4">Остаток:</span>
      </v-col>
      <v-col cols="3" class="text-right font-weight-bold">
        <div>{{ (totals.remain_total_uzs || 0)|money }}</div>
        <div>{{ (totals.remain_total_usd || 0)|money('usd') }}</div>
      </v-col>
    </v-row>

    <v-data-table
      :items="report" :headers="nonSortableHeaders"
      :loading="!!fetching" :items-per-page="filter.limit"
      :height="dataTableHeight" disable-items-per-page hide-default-footer fixed-header
      no-data-text="Нет данных" loading-text="Данные загружаются"
    >
      <template #item="{item, index}">
        <tr>
          <td class="text-center fixed">{{filter.limit * (filter.page - 1) + index + 1}}</td>
          <td class="fixed">{{item.name}}</td>
          <td class="text-right">{{item.products_total|prettyInt}}</td>
          <td class="text-right">{{item.consignment_elements_total|prettyInt}}</td>
          <td class="text-right">{{item.returned_products_total|prettyInt}}</td>
          <td class="text-right">{{item.remain|prettyInt}}</td>
          <td class="text-right">{{(item.sell_price || 0)|money(null, 0, false)}}</td>
          <td class="text-right">{{(item.sell_price_usd || 0)|money('usd', 0, false)}}</td>

          <td class="text-right">{{(item.product_total_uzs || 0)|money(null, 0, false)}}</td>
          <td class="text-right">{{(item.product_total_usd || 0)|money('usd', 0, false)}}</td>

          <td class="text-right">{{(item.sales_total_uzs || 0)|money(null, 0, false)}}</td>
          <td class="text-right">{{(item.sales_total_usd || 0)|money('usd', 0, false)}}</td>

          <td class="text-right">{{(item.remain_uzs || 0)|money(null, 0, false)}}</td>
          <td class="text-right">{{(item.remain_usd || 0)|money('usd', 0, false)}}</td>
        </tr>
      </template>
    </v-data-table>

    <pagination ref="paginationContainer" :limit.sync="limit" :page.sync="page" :total-pages="totalPages"></pagination>
  </v-container>
</template>

<script>
import {createNamespacedHelpers, mapState as mapStateDefault} from 'vuex'

const {mapState, mapActions} = createNamespacedHelpers('Report')
import {hasFilter} from '@/mixins'

export default {
  name: 'report-product',

  mixins: [hasFilter],

  customFilters: {
    report: 'productReport',
  },

  created() {
    this.fetch(this.filter)
  },

  computed: {
    ...mapState([
      'fetching',
      'data',
    ]),

    ...mapStateDefault('Product', {
      products: state => (state.data?.results || []),
      productsLoading: state => !!state.fetching,
    }),

    headers() {
      return [
        {text: '#', align: 'center', width: '60', class: 'fixed'},
        {text: 'Название', width: '250', class: 'fixed'},
        {text: 'Произведено', align: 'right'},
        {text: 'Продано', align: 'right'},
        {text: 'Возврат', align: 'right'},
        {text: 'Остаток', align: 'right'},
        {text: 'Цена продажи, сум', align: 'right', width: '150'},
        {text: 'Цена продажи, $', align: 'right', width: '140'},
        {text: 'Произведено, сум', align: 'right', width: '140'},
        {text: 'Произведено, $', align: 'right', width: '140'},
        {text: 'Продано, сум', align: 'right', width: '120'},
        {text: 'Продано, $', align: 'right', width: '120'},
        {text: 'Остаток, сум', align: 'right', width: '120'},
        {text: 'Остаток, $', align: 'right', width: '120'},
      ]
    },

    report() {
      return this.data?.results || []
    },

    totals() {
      return this.data?.total || {}
    },
  },

  methods: {
    ...mapActions(['fetch']),
  },

  mounted() {
    this.$store.dispatch('Product/fetch')
    this.$eventBus.$emit('setTitle', 'Отчет по остатку продуктов')
  },
}
</script>

<style scoped>
.fixed {
  position: sticky;
  background: white;
  left: 0;
  top: auto;
  z-index: 1;
}
.fixed + .fixed {
  left: 60px;
}

th:not(.fixed),
td:not(.fixed) {
  z-index: 0;
}
</style>
