<template>
  <v-container fluid>
    <v-row ref="filtersContainer">
      <v-col cols="3" class="d-flex align-center">
        <span class="text-h5">Операции</span>
      </v-col>
      <v-col cols="9" class="d-inline-flex font-weight-bold justify-end align-center">
        <span class="success--text text--darken-3">Сумма дебетов: {{totals.debet_summa || 0 | money}}</span>
        <span class="error--text text--darken-2 ml-3">Сумма кредитов: {{totals.credit_summa || 0 | money}}</span>

        <span v-if="totals.debet_summa - totals.credit_summa > 0" class="primary--text ml-3">Прибыль: {{totals.debet_summa - totals.credit_summa || 0 | money}}</span>
        <span v-else class="error--text text--darken-2 ml-3">Убыток: {{totals.debet_summa - totals.credit_summa || 0 | money}}</span>
      </v-col>

      <v-col cols="2">
        <v-menu v-model="fromDateMenu" :close-on-content-click="false"
                transition="scale-transition" offset-y max-width="290px" min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="fromDateFormatted"
                          label="с" prepend-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.reg_date_after" @input="dateChanged"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2">
        <v-menu v-model="toDateMenu" :close-on-content-click="false"
                transition="scale-transition" offset-y max-width="290px" min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="toDateFormatted"
                          label="по" prepend-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.reg_date_before" @input="dateChanged"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2">
        <v-text-field v-model="filter.amount_min" :disabled="!!fetching" type="number" ref="amount_min_filter"
                      label="Мин сумма" prepend-inner-icon="mdi-format-horizontal-align-left"
                      clearable hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field v-model="filter.amount_max" :disabled="!!fetching" type="number" ref="amount_max_filter"
                      label="Макс сумма" append-icon="mdi-format-horizontal-align-right"
                      clearable hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-select v-model="filter.source" :disabled="!!fetching"
                  label="Источник" :items="SourceTypes"
                  item-value="id" item-text="label"
                  clearable hide-details
        ></v-select>
      </v-col>
      <v-col cols="2">
        <v-select v-model="filter.type" :disabled="!!fetching"
                  label="Тип операций" :items="PaymentTypes"
                  item-value="id" item-text="altName"
                  clearable hide-details
        ></v-select>
      </v-col>
    </v-row>

    <v-data-table :items="report"
      :headers="nonSortableHeaders"
      :loading="!!fetching" :items-per-page="filter.limit"
      :height="dataTableHeight" disable-items-per-page hide-default-footer
      no-data-text="Нет данных" loading-text="Данные загружаются"
    >
      <template #item="{item, index}">
        <tr>
          <td class="text-center">{{filter.limit * (filter.page - 1) + index + 1}}</td>
          <td class="text-center">{{(getPaymentType(item.type)|| {}).altName}}</td>
          <td class="text-center">{{item.reg_date|date}}</td>
          <td>{{getSourceType(item.source).label}}</td>
          <td>{{item.source_object || ''}}</td>
          <td class="text-right">{{item.amount|money}}</td>
          <td class="text-right">
            <v-btn text small depressed color="success" @click="showDetails(item)">
              <v-icon left small>mdi-eye</v-icon> Подробно
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <pagination ref="paginationContainer" :limit.sync="limit" :page.sync="page" :total-pages="totalPages"></pagination>
    <v-dialog v-model="showDetailsDialog" width="70vw">
      <operation-detail v-if="showDetailsDialog"
                        :item-id="detailItem.id"
                        @dialog:close="closeDetailsDialog"
      ></operation-detail>
    </v-dialog>
  </v-container>
</template>

<script>
  import {mapState as defaultMapState, mapGetters, createNamespacedHelpers} from 'vuex'
  import moment from 'moment'
  import {SERVER_DATE_FORMAT, USER_DATE_FORMAT} from '@/filters'
  import {hasFilter} from '@/mixins'

  const {mapState, mapActions} = createNamespacedHelpers('Report')

  import OperationDetail from '@/modules/report/details/operation'

  export default {
    name: 'report-operations',

    mixins: [hasFilter],

    customFilters: {
      report: 'operations',
      reg_date_before: moment().format(SERVER_DATE_FORMAT),
      reg_date_after: moment().subtract(1, 'month').format(SERVER_DATE_FORMAT),
      amount_min: '',
      amount_max: '',
      source: null,
      type: null,
    },

    refocusable: {
      amount_min: 'amount_min',
      amount_max: 'amount_max_filter',
    },

    data() {
      return {
        toDateMenu: false,
        fromDateMenu: false,
        detailItem: null,
        showDetailsDialog: false,
      }
    },

    created() {
      this.fetch(this.filter)
    },

    computed: {
      ...defaultMapState('Payment', ['PaymentTypes']),
      ...mapGetters('Payment', ['getPaymentType']),
      ...mapGetters('UniversalExpense', {
        getSourceType: 'getType',
      }),
      ...mapGetters('TransportExpense', {getTransportExpenseType: 'getType'}),

      ...mapState(['fetching', 'data', 'SourceTypes']),

      headers() {
        return [
          {text: '#', align: 'center', width: '80px'},
          {text: 'Операция', width: '150px', align: 'center'},
          {text: 'Дата', width: '120px', align: 'center'},
          {text: 'Тип', align: 'left'},
          {text: 'Детали', align: 'left'},
          {text: 'Сумма', width: '150px', align: 'center'},
          {text: '', align: 'right'},
        ]
      },

      report() {
        return this.data?.results || []
      },

      totals() {
        return this.data?.total || {}
      },

      toDateFormatted() {
        return moment(this.filter.reg_date_before).format(USER_DATE_FORMAT)
      },

      fromDateFormatted() {
        return moment(this.filter.reg_date_after).format(USER_DATE_FORMAT)
      },
    },

    methods: {
      ...mapActions(['fetch']),

      dateChanged() {
        this.toDateMenu = false
        this.fromDateMenu = false
        this.$nextTick(() => this.fetch(this.filter))
      },

      showDetails(item) {
        this.detailItem = item
        this.showDetailsDialog = true
      },

      closeDetailsDialog() {
        this.detailItem = null
        this.showDetailsDialog = false
      }
    },

    mounted() {
      this.$eventBus.$emit('setTitle', 'Операции')
    },

    components: {
      OperationDetail,
    }
  }
</script>
