<template>
  <v-container fluid>
    <v-row ref="filtersContainer">
      <v-col cols="6" class="d-flex align-center">
        <span class="text-h5 pt-3">Отчет продаж по месяцам</span>
      </v-col>
      <v-col cols="2">
        <v-select v-model="filter.year"
                  :items="years"
                  item-value="id" item-text="value"
                  label="за год" prepend-icon="mdi-calendar" hide-details
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div v-if="!!fetching" class="ma-4 text-center">
          <v-progress-linear color="primary" indeterminate/>
        </div>
        <v-simple-table v-else class="report-by-month" fixed-header :height="dataTableHeight">
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">
                Продукт
              </th>
              <th v-for="i in 12" class="text-center">
                {{getHeaderDate(i)}}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="product in products" :key="product.id">
              <td style="width: 200px;">{{product.name}}</td>
              <td v-for="i in 12" :key="i + product.id" @click="showDetails(product.id, i)" class="text-center month-detail">
                {{getCellData(product.id, i).amount_sum|prettyInt}}
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {createNamespacedHelpers} from 'vuex'
  import moment from 'moment'
  import {hasFilter} from '@/mixins'
  import {SERVER_DATE_FORMAT} from "@/filters";

  const {mapState, mapActions} = createNamespacedHelpers('Report')

  export default {
    name: 'report-consignments-by-months',

    mixins: [hasFilter],

    customFilters: {
      year: (new Date).getFullYear(),
      report: 'consignmentsByMonths',
    },

    data() {
      return {
        years: [2022, 2023, 2024, 2025, 2026].filter(d => 1 || d <= (new Date).getFullYear()).map(v => {
          return {id: v, value: v}
        }),
      }
    },

    created() {
      this.fetch(this.filter)
    },

    computed: {
      ...mapState(['fetching', 'data']),

      products() {
        return this.report.reduce((acc, reportItem) => {
          if (!acc.find(p => p.id === reportItem.id)) {
            acc.push({
              id: reportItem.id,
              name: reportItem.name,
            })
            return acc
          }

          return acc
        }, [])
      },

      report() {
        return this.data || []
      },
    },

    methods: {
      ...mapActions(['fetch']),

      getHeaderDate(month) {
        return moment(this.getCellDate(month)).locale('ru').format('MMM')
      },

      getCellDate(month) {
        return `${this.filter.year}-${month.toString().padStart(2, '0')}-01`
      },

      getCellData(productId, month) {
        return this.report.find(rI => {
          return rI.id === productId && rI.month === this.getCellDate(month)
        }) || {amount_sum: 0}
      },

      showDetails(productId, month) {
        this.$router.push({
          name: 'consignment-item.list',
          query: {
            product_type: productId,
            consignment_doc__reg_date_after: this.getCellDate(month),
            consignment_doc__reg_date_before: moment(this.getCellDate(month)).endOf('month').format(SERVER_DATE_FORMAT),
          }
        })
      }
    },

    mounted() {
      this.$eventBus.$emit('setTitle', 'Отчет продаж по месяцам')
    },
  }
</script>

<style scoped>
  .report-by-month tr>th:first-child,
  .report-by-month tr>td:first-child {
    min-width: 200px;
  }
  .report-by-month tr>th:not(:first-child),
  .report-by-month tr>td:not(:first-child) {
    border-left: 1px solid #eee;
  }
  .month-detail:hover {
    background: #dedede;
    cursor: pointer;
  }
</style>
