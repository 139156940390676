<template>
  <v-container fluid>
    <v-row ref="filtersContainer">
      <v-col cols="12" class="d-flex align-center">
        <span class="text-h5">Отчет по производству и расходам</span>
      </v-col>
      <v-col cols="2">
        <v-menu v-model="fromDateMenu" :close-on-content-click="false"
                transition="scale-transition" offset-y max-width="290px" min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="fromDateFormatted"
                          label="с" prepend-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.from_date" @input="dateChanged"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2">
        <v-menu v-model="toDateMenu" :close-on-content-click="false"
                transition="scale-transition" offset-y max-width="290px" min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="toDateFormatted"
                          label="по" prepend-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.to_date" @input="dateChanged"></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <div v-if="!!fetching" class="ma-4 text-center">
      <v-progress-linear color="primary" indeterminate/>
    </div>
    <v-simple-table v-else id="producement-report" class="report-by-month" fixed-header height="calc(100vh - 200px)">
      <template v-slot:default>
        <thead>
        <tr>
          <th style="min-width: 200px;" class="fixed text-left bold-border-right" rowspan="2">Продукт</th>
          <th colspan="4">До</th>
          <th v-for="date in intervalDates" :key="date" colspan="4">{{date|date}}</th>
          <th id="fixed-right-th" class="fixed fixed-right bold-border-left" colspan="4">После</th>
        </tr>
        <tr>
          <template v-for="i in intervalDates.length + 2">
            <th :class="intervalDates.length + 2 === i ? 'fixed fixed-right bold-border-left' : ''" class="border-left">Произв.</th>
            <th :class="intervalDates.length + 2 === i ? 'fixed fixed-right' : ''">Прод.</th>
            <th :class="intervalDates.length + 2 === i ? 'fixed fixed-right' : ''">Возвр.</th>
            <th :class="intervalDates.length + 2 === i ? 'fixed fixed-right' : ''">Остат.</th>
          </template>
        </tr>
        </thead>
        <tbody>
        <tr v-for="productId in productIds" :key="productId"
            @mouseenter="hoveredLine = productId"
            @mouseleave="hoveredLine = null"
            :class="{'hovered-tr': hoveredLine === productId}"
        >
          <td class="fixed bold-border-right">{{ (getProduct(productId) || {name: 'Продукт'}).name }}</td>

          <td>{{getCell(productId, 'from','amounts') | prettyInt}}</td>
          <td>{{getCell(productId, 'from', 'cons_amounts') | prettyInt}}</td>
          <td>{{getCell(productId, 'from', 'returned_amount') | prettyInt}}</td>
          <td>{{getCell(productId, 'from', 'remain') | prettyInt}}</td>

          <template v-for="date in intervalDates">
            <td>{{getDateCell(productId, date, 'amounts') | prettyInt}}</td>
            <td>{{getDateCell(productId, date, 'cons_amount') | prettyInt}}</td>
            <td>{{getDateCell(productId, date, 'returned_amount') | prettyInt}}</td>
            <td>{{getDateCell(productId, date, 'remain') | prettyInt}}</td>
          </template>

          <td class="fixed fixed-right bold-border-left">{{getCell(productId, 'to', 'amounts') | prettyInt}}</td>
          <td class="fixed fixed-right">{{getCell(productId, 'to', 'cons_amounts') | prettyInt}}</td>
          <td class="fixed fixed-right">{{getCell(productId, 'to', 'returned_amount') | prettyInt}}</td>
          <td class="fixed fixed-right">{{getCell(productId, 'to', 'remain') | prettyInt}}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
import {createNamespacedHelpers, mapState as mapStateDefault, mapGetters as defaultMapGetters} from 'vuex'
import moment from 'moment'
import {SERVER_DATE_FORMAT, USER_DATE_FORMAT} from '@/filters'
import {hasFilter, hasDialog} from '@/mixins'

const {mapState, mapActions, mapGetters} = createNamespacedHelpers('Report')

export default {
  name: 'report-producement',

  mixins: [hasFilter, hasDialog],

  customFilters: {
    report: 'producement',
    limit: 1000,
    from_date: moment().subtract(2, 'day').format(SERVER_DATE_FORMAT),
    to_date: moment().format(SERVER_DATE_FORMAT),
  },

  data() {
    return {
      hoveredLine: null,
    }
  },

  created() {
    this.fetch(this.filter)
  },

  computed: {
    ...mapState(['fetching', 'data']),
    ...mapStateDefault('Product', {
      products: state => (state.data?.results || []),
      productsLoading: state => !!state.fetching,
    }),
    ...defaultMapGetters('Product', ['getProduct']),

    report() {
      return this.data || {}
    },

    toDateFormatted() {
      return moment(this.filter.to_date).format(USER_DATE_FORMAT)
    },

    fromDateFormatted() {
      return moment(this.filter.from_date).format(USER_DATE_FORMAT)
    },

    productIds() {
      let productIds = []

      this.report.from_date_result?.map(f => {
        if (productIds.indexOf(f.id) === -1) {
          productIds.push(f.id)
        }
      })

      this.report.to_date_result?.map(f => {
        if (productIds.indexOf(f.id) === -1) {
          productIds.push(f.id)
        }
      })

      this.report.collected_datas?.map(c => {
        if (productIds.indexOf(c.id) === -1) {
          productIds.push(c.id)
        }
      })

      return productIds
    },

    intervalDates() {
      let startDate = moment(this.filter.from_date)
      let endDate = moment(this.filter.to_date)
      let dates = [];

      for (let m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, 'days')) {
        dates.push(m.format('YYYY-MM-DD'));
      }

      return dates
    }
  },

  methods: {
    ...mapActions(['fetch']),

    getCell(productId, type, field) {
      return (this.report[type + '_date_result'].find(i => i.id === productId) || {})[field] || 0
    },

    getDateCell(productId, date, field) {
      let el = (this.report.collected_datas.find(i => i.id === productId))

      return (el?.dates.find(e => e.date === date) || {})[field] || 0
    },

    dateChanged() {
      this.toDateMenu = false
      this.fromDateMenu = false
      this.$nextTick(() => this.fetch(this.filter))
    },
  },

  mounted() {
    this.$store.dispatch('Product/fetch')
    this.$eventBus.$emit('setTitle', 'Отчет по производству и расходам')
  },
}
</script>

<style scoped>
#producement-report thead td {
  min-width: 100px;
}
#producement-report thead th:not(:first-child),
#producement-report td:not(:first-child) {
  border-left: 1px solid #eee;
  text-align: center!important;
  min-width: 90px;
  padding-left: 5px;
  padding-right: 5px;
}
.border-left {
  border-left: 1px solid #eee;
}

.fixed {
  position: sticky;
  background: white;
  left: 0;
  top: auto;
  z-index: 5;
  border-right: 1px solid #eee;
}
th.fixed, th.fixed-right {
  z-index: 6!important;
}

#fixed-right-th.fixed.fixed-right {
  right: 0;
}
.fixed.fixed-right {
  left: auto;
  right: 270px;
}
th.fixed-right + .fixed-right,
.fixed-right + .fixed-right {
  right: 180px;
}
th.fixed-right + .fixed-right + .fixed-right,
.fixed-right + .fixed-right + .fixed-right {
  right: 90px;
}
th.fixed-right + .fixed-right + .fixed-right + .fixed.fixed-right,
.fixed-right + .fixed-right + .fixed-right + .fixed.fixed-right {
  right: 0;
}
.bold-border-left {
  border-left: 2px solid #aaa!important;
}
.bold-border-right {
  border-right: 2px solid #aaa!important;
}
.hovered-tr td {
  background: #eee;
}
</style>
